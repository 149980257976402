import React from "react";
import { Link } from "react-router-dom";
import userInfo from "../assets/images/png/user-info-img.png";
import { userInfoData } from "../components/common/Helper";
import PrimaryButton from "../components/common/PrimaryButton";
import CommonInput from "../components/dashboard/CommonInput";

const NewUser = () => {
  return (
    <div>
      <div className="bg-user-info w-full bg-[length:100%_100%] bg-no-repeat sm:p-7 p-4">
        <nav className="flex justify-center md:justify-end items-center custom-2xl:mt-[50px] mb-3 lg:mt-6 md:px-5 lg:px-20 text-base lg:text-lg font-normal gap-6 lg:gap-10 w-full md:absolute top-0 right-0 z-10">
          <Link className="text-white" to="/">
            Home
          </Link>
          <Link to="/#contact-us" className="text-white">
            Contact
          </Link>
          <Link
            to={"/login"}
            className="text-white border-white border px-7 py-1 rounded-2xl hover:bg-white hover:text-secondary transition-all duration-300"
          >
            Login
          </Link>
        </nav>
        <img
          className="w-full lg:max-w-[500px] md:max-w-[400px] mx-auto max-w-[350px]"
          src={userInfo}
          alt="userInfo"
        />
      </div>
      <div className="container custom_container mx-auto px-3 mb-[40px]">
        <h4 className="text-2xl md:text-[32px] font-bold mb-2 text-center mt-10">
          Welcome! New user
        </h4>
        <p className="text-center custom-2xl:mb-12 mb-3 lg:mb-8 max-w-[333px] mx-auto text-sm font-normal">
          Use these awesome forms to create new account in your project for
          free.
        </p>
        <div className="lg:mt-[50px] mt-14 shadow-[0px_4px_24px_0px_#00000040] sm:px-[30px] p-5 sm:pb-10 pb-6 rounded-2xl">
          <form>
            {userInfoData.map((obj, index) => {
              return (
                <div key={index}>
                  <div className="flex items-center mb-7 mt-3 gap-1">
                    {obj.icon}
                    <p className="sm:text-[26px] text-xl font-bold">
                      {obj.title}
                    </p>
                  </div>
                  <div
                    className={`grid gap-x-6 custom-2xl:gap-x-[50px] gap-y-[22px] sm:mb-4 ${
                      obj.title === "Password"
                        ? "grid-cols-1"
                        : "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3"
                    }`}
                  >
                    {obj.inputs.map((input, i) => {
                      return (
                        <CommonInput
                          key={i}
                          type={input.type}
                          label={input.label}
                          name={input.name}
                          required
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <p className="font-semibold sm:text-[21px] text-lg text-black">
              Terms And Conditions
            </p>
            <div className="flex items-center justify-between mt-3 mb-4 sm:mb-[30px] flex-wrap gap-1">
              <Link
                to="/terms-conditions?q=new-user"
                className="text-blueberry sm:text-base text-sm"
              >
                View Terms and Conditions
              </Link>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  name="terms"
                  id="terms"
                  className="w-4 h-4 cursor-pointer"
                />
                <label
                  htmlFor="terms"
                  className="text-black sm:text-base text-sm font-normal cursor-pointer"
                >
                  Accept Terms and Conditions
                </label>
              </div>
            </div>
            <PrimaryButton
              text="Submit!"
              type="submit"
              className="w-full !rounded"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewUser;
