import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CommonInput = ({
  label,
  type,
  value,
  onChange,
  name,
  className = "",
  labelClassName = "",
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (onChange) {
      const syntheticEvent = {
        target: {
          name,
          value: date ? date.toISOString().split("T")[0] : "",
        },
      };
      onChange(syntheticEvent);
    }
  };

  const renderLabel = (labelText) => {
    if (!labelText) return null;

    const parts = labelText.split("*");
    return (
      <>
        {parts[0]}
        {parts.length > 1 && <span className="text-[#FF0000]">*</span>}
      </>
    );
  };

  // Rest of your icon components remain the same...
  const ShowPasswordIcon = () => (
    <svg
      className="max-w-6 xl:max-w-none"
      width="37"
      height="22"
      viewBox="0 0 37 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.8993 11.3029C34.0993 14.4152 31.4716 17.0368 28.3278 18.8579C25.2933 20.6116 21.7634 21.6306 18.0183 21.6306C14.2746 21.6306 10.7449 20.6116 7.71032 18.8579C4.55759 17.0324 1.92275 14.3972 0.121242 11.273C-0.046344 10.9827 -0.0343735 10.6341 0.121242 10.3588H0.119746C1.90784 7.1971 4.52028 4.57543 7.6506 2.76818C10.6971 1.00552 14.2479 0 18.0184 0C21.7906 0 25.3412 1.00552 28.3876 2.76818C31.5179 4.57574 34.1304 7.19706 35.9185 10.3588C36.0906 10.6625 36.0714 11.0231 35.8993 11.3029ZM27.4016 17.2628C30.1279 15.6842 32.4261 13.4548 34.0495 10.8122C32.4365 8.14129 30.1623 5.92214 27.4629 4.36311C24.6917 2.76056 21.4581 1.84632 18.0183 1.84632C14.5798 1.84632 11.3463 2.76056 8.57526 4.36311C5.87591 5.92225 3.60168 8.14129 1.9871 10.8122C3.6121 13.4547 5.90881 15.6843 8.63655 17.2628C11.3942 18.8579 14.6068 19.7841 18.0183 19.7841C21.4314 19.7841 24.644 18.8579 27.4016 17.2628ZM18.0183 4.27041C19.8468 4.27041 21.4853 4.99312 22.6659 6.17373L22.6973 6.20814L22.7138 6.2246C23.866 7.39771 24.5677 9.01971 24.5677 10.8151C24.5677 12.5972 23.8315 14.2522 22.639 15.4447L22.6045 15.4761L22.5881 15.4926C21.4165 16.6373 19.805 17.36 18.0182 17.36C16.2062 17.36 14.5767 16.6178 13.402 15.4447L13.3991 15.4417V15.4447C12.2065 14.2522 11.4703 12.5973 11.4703 10.8151C11.4703 8.99263 12.1915 7.35117 13.3721 6.17366C14.5527 4.99308 16.1913 4.27041 18.0183 4.27041ZM21.3611 7.47699C20.5202 6.63607 19.3396 6.11834 18.0183 6.11834C16.6986 6.11834 15.518 6.63607 14.677 7.47699L14.6741 7.4755V7.47699C13.8331 8.31642 13.3169 9.49401 13.3169 10.8152C13.3169 12.0946 13.8451 13.2826 14.704 14.1415H14.701C15.5419 14.9795 16.712 15.5121 18.0183 15.5121C19.3066 15.5121 20.4618 14.9944 21.2982 14.1789L21.3027 14.1744L21.3341 14.1415C22.193 13.2826 22.7212 12.0945 22.7212 10.8152C22.7212 9.51643 22.2214 8.35229 21.403 7.51585L21.3955 7.50987L21.3611 7.47699Z"
        fill="black"
      />
    </svg>
  );

  const HidePasswordIcon = () => (
    <svg
      className="max-w-6 xl:max-w-none"
      width="37"
      height="26"
      viewBox="0 0 37 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_16003_222)">
        <path
          d="M35.8993 13.3029C34.0993 16.4152 31.4716 19.0368 28.3278 20.8579C25.2933 22.6116 21.7634 23.6306 18.0183 23.6306C14.2746 23.6306 10.7449 22.6116 7.71032 20.8579C4.55759 19.0324 1.92275 16.3972 0.121242 13.273C-0.0463439 12.9827 -0.0343734 12.6341 0.121242 12.3588H0.119746C1.90784 9.1971 4.52028 6.57543 7.6506 4.76818C10.6971 3.00552 14.2479 2 18.0184 2C21.7906 2 25.3412 3.00552 28.3876 4.76818C31.5179 6.57574 34.1304 9.19706 35.9185 12.3588C36.0906 12.6625 36.0714 13.0231 35.8993 13.3029ZM27.4016 19.2628C30.1279 17.6842 32.4261 15.4548 34.0495 12.8122C32.4365 10.1413 30.1623 7.92214 27.4629 6.36311C24.6917 4.76056 21.4581 3.84632 18.0183 3.84632C14.5798 3.84632 11.3463 4.76056 8.57526 6.36311C5.87591 7.92225 3.60168 10.1413 1.9871 12.8122C3.6121 15.4547 5.90881 17.6843 8.63655 19.2628C11.3942 20.8579 14.6068 21.7841 18.0183 21.7841C21.4314 21.7841 24.644 20.8579 27.4016 19.2628ZM18.0183 6.27041C19.8468 6.27041 21.4853 6.99312 22.6659 8.17373L22.6973 8.20814L22.7138 8.2246C23.866 9.39771 24.5677 11.0197 24.5677 12.8151C24.5677 14.5972 23.8315 16.2522 22.639 17.4447L22.6045 17.4761L22.5881 17.4926C21.4165 18.6373 19.805 19.36 18.0182 19.36C16.2062 19.36 14.5767 18.6178 13.402 17.4447L13.3991 17.4417V17.4447C12.2065 16.2522 11.4703 14.5973 11.4703 12.8151C11.4703 10.9926 12.1915 9.35117 13.3721 8.17366C14.5527 6.99308 16.1913 6.27041 18.0183 6.27041ZM21.3611 9.47699C20.5202 8.63607 19.3396 8.11834 18.0183 8.11834C16.6986 8.11834 15.518 8.63607 14.677 9.47699L14.6741 9.4755V9.47699C13.8331 10.3164 13.3169 11.494 13.3169 12.8152C13.3169 14.0946 13.8451 15.2826 14.704 16.1415H14.701C15.5419 16.9795 16.712 17.5121 18.0183 17.5121C19.3066 17.5121 20.4618 16.9944 21.2982 16.1789L21.3027 16.1744L21.3341 16.1415C22.193 15.2826 22.7212 14.0945 22.7212 12.8152C22.7212 11.5164 22.2214 10.3523 21.403 9.51585L21.3955 9.50987L21.3611 9.47699Z"
          fill="black"
        />
      </g>
      <line
        x1="1.6"
        y1="1.2"
        x2="33.6"
        y2="25.2"
        stroke="black"
        stroke-width="2"
      />
      <defs>
        <clipPath id="clip0_16003_222">
          <rect
            width="37"
            height="22"
            fill="white"
            transform="translate(0 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <div className="mb-4 relative">
      <style>
        {`
          .react-datepicker {
  font-family: inherit;
  border: 1px solid #4978fc;
  border-radius: 10px;
  overflow: hidden;
}
.react-datepicker-popper {
  z-index: 11 !important;
}
.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
  display: none !important;
}
.react-datepicker__header {
  background-color: #4978fc;
  border-bottom: none;
  padding-top: 10px;
}
.react-datepicker__current-month {
  color: white;
  font-weight: 600;
  font-size: 14px;
}
.react-datepicker__day-name {
  color: white;
}
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #4978fc !important;
  color: white !important;
  border-radius: 4px;
}
.react-datepicker__day:hover {
  background-color: rgba(73, 120, 252, 0.1);
  border-radius: 4px;
}
.react-datepicker__navigation-icon::before {
  border-color: white;
}
.react-datepicker__day--today {
  font-weight: bold;
  color: #4978fc;
}
.icon-svg:hover .icon-circle {
  fill: #2b59ff;
}
.react-datepicker-wrapper {
  width: 100%;
}
  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle{
  fill:#4978FC;
  color:#4978FC;
  }
  .react-datepicker-popper .react-datepicker__triangle{
  stroke:#4978FC}
        `}
      </style>
      {label && (
        <label
          htmlFor={name}
          className={`absolute start-3 -top-4 text-base leading-5 md:text-lg sm:leading-7 font-medium text-black px-2 py-1 bg-white z-[1] ${labelClassName}`}
        >
          {renderLabel(label)}
        </label>
      )}
      {type === "date" ? (
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="yyyy-MM-dd"
          className={`px-5 w-full h-12 md:h-16 border border-black rounded-[10px] focus:outline-none focus:ring-2 focus:ring-[#4978FC] focus:border-transparent text-[#9A9A9A] ${className}`}
          placeholderText="YYYY-MM-DD"
          required
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={100}
          {...rest}
        />
      ) : (
        <input
          required
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          value={value}
          onChange={onChange}
          name={name}
          id={name}
          className={`px-2 w-full h-12 md:h-16 border border-black rounded-[10px] focus:outline-none focus:ring-2 focus:ring-[#4978FC] focus:border-transparent [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${className}`}
          {...rest}
        />
      )}
      {type === "password" && (
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
        >
          {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
        </button>
      )}
    </div>
  );
};

export default CommonInput;
