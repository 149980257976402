import React from "react";
import UserLayout from "../components/common/UserLayout";
import ProfileDetails from "../components/dashboard/ProfileDetails";
import BudgetTable from "../components/budget/BudgetTable";
import TermsConditionsFooter from "../components/common/TermsConditionsFooter";

const Budget = () => {
  return (
    <UserLayout title="Budget">
      <ProfileDetails />
      <BudgetTable />
      <TermsConditionsFooter/>
    </UserLayout>
  );
};

export default Budget;
