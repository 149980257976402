import React from "react";
import UserLayout from "../components/common/UserLayout";
import ProfileDetails from "../components/dashboard/ProfileDetails";
import ReportTable from "../components/reports/ReportTable";
import TermsConditionsFooter from "../components/common/TermsConditionsFooter";

const Reports = () => {
  return (
    <UserLayout title="Reports">
      <ProfileDetails />
      <ReportTable />
      <TermsConditionsFooter />
    </UserLayout>
  );
};

export default Reports;
