import React from "react";
import UserLayout from "../components/common/UserLayout";
import { termsData } from "../components/terms-conditions/Helper";
import TermsConditionsFooter from "../components/common/TermsConditionsFooter";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { BackArrow } from "../components/common/Icons";

const TermsConditions = () => {
  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("q");

  const renderSection = (section, index) => {
    // Check if the section is a subsection (like Withdrawal from E-Wallet)
    const isSubsection = section.id === "6W";
    const isLastSection = index === termsData.length - 1;

    return (
      <div
        key={section.id}
        className={`${!isLastSection ? "mb-4 xl:mb-8" : ""}`}
      >
        <h2
          className={`${
            isSubsection ? "text-lg xl:text-xl" : "text-xl xl:text-[26px]"
          } mb-3 text-black font-manrope font-semibold`}
        >
          {/* Don't show number for subsections */}
          {!isSubsection && `${section.id}. `}
          {section.title}
        </h2>

        <div className="space-y-3 xl:space-y-5">
          {section.clauses.map((clause) => (
            <div key={clause.id} className="pl-3 xl:pl-6">
              <div className="flex">
                <span className="text-lightBlack font-medium text-sm xl:text-base mr-2">
                  {clause.id}
                </span>
                <p className="text-lightBlack text-sm xl:text-base">
                  {clause.text}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {query === "new-user" ? (
        <div className="h-screen flex flex-col container mx-auto px-4 justify-between">
          <Link
            to={"/new-user"}
            className="absolute left-2 sm:left-3 xl:left-5 top-3 xl:top-5"
          >
            <BackArrow />
          </Link>
          <div>
            <h5 className="font-manrope font-bold text-black text-xl xl:text-[32px] truncate py-3 sm:py-6 leading-[150%] text-center">
              TERMS AND CONDITIONS
            </h5>
          </div>
          <div className="shadow-[0px_4px_24px_0px_#00000040] p-4 sm:p-6 xl:p-10 rounded-[10px] bg-white overflow-y-auto lg:max-h-[calc(100vh-100px)]">
            {termsData.map((section, index) => renderSection(section, index))}
          </div>
          <TermsConditionsFooter />
        </div>
      ) : (
        <UserLayout title="CONSUMER CARDS TERMS AND CONDITIONS">
          <div className="shadow-[0px_4px_24px_0px_#00000040] p-4 sm:p-6 xl:p-10 rounded-[10px] bg-white lg:max-h-[calc(100vh-200px)] overflow-y-auto">
            {termsData.map((section, index) => renderSection(section, index))}
          </div>
          <TermsConditionsFooter />
        </UserLayout>
      )}
    </>
  );
};

export default TermsConditions;
