export const termsData = [
  {
    id: 1,
    title: "THE CARD",
    clauses: [
      {
        id: "1.1",
        text: 'The Card is a VISA prepaid card issued by <Company Name> ("<Company Abbreviation>"), a major payment institution licensed by the Monetary Authority of <Country Name> on behalf of GCL ("Partner") to such individuals nominated by Partner and approved by <Company Abbreviation> in writing from time to time ("You" or "Your").',
      },
      {
        id: "1.2",
        text: "Notwithstanding clause 1.1, the Card remains the property of <Company Abbreviation> at all times and may be recalled or replaced by <Company Abbreviation> at any time in <Company Abbreviation>'s absolute discretion.",
      },
      {
        id: "1.3",
        text: 'Installation of the <Company Abbreviation> PAY APP (as defined below) constitutes Your acceptance and agreement to be bound by these terms and conditions ("Terms and Conditions").',
      },
    ],
  },
  {
    id: 2,
    title: "ELIGIBILITY",
    clauses: [
      {
        id: "2.1",
        text: "You must be an employee of the Partner and satisfy any other due diligence checks conducted by <Company Abbreviation> to be eligible for the Card.",
      },
      {
        id: "2.2",
        text: "Notwithstanding clause 2.1, <Company Abbreviation> may, in its sole discretion and without having to assign any reason, refuse to issue the Card to You or to recall, suspend or terminate a Card that has been issued to You.",
      },
      {
        id: "2.3",
        text: 'Your Card shall be valid for the period stated on the Card ("Validity Period").Renewal or replacement of the Card will be at <Company Abbreviation>\'s sole discretion.',
      },
      {
        id: "2.4",
        text: "You shall deliver or cause to be delivered to <Company Abbreviation> such information as <Company Abbreviation> may reasonably request including but not limited to documents and/or information regarding Your financial affairs and/or identity. You represent and warrant that all information provided by You or any third party on Your behalf is accurate in all material respects and You will not omit or withhold any information which would make such information inaccurate in any material respect.",
      },
    ],
  },
  {
    id: 3,
    title: "SCOPE OF USE",
    clauses: [
      {
        id: "3.1",
        text: "No one else but You may use the Card for personal purposes during the Validity Period in accordance with applicable laws, the VISA rules and these Terms and Conditions.",
      },
      {
        id: "3.2",
        text: 'The Card is denominated in <Country Name> Dollars ("Card Currency"). You may use the Card to make payments in the Card Currency or a Supported Currency (as defined in Clause 6.1(b)) for goods or services where VISA prepaid cards are accepted as a means of payment.',
      },
      {
        id: "3.3",
        text: 'You are fully responsible for ensuring that You only use the Cardin compliance with any applicable laws and the VISA regulations. You shall not use the Card in connection with any activities listed in Appendix A or any other illegal activities including but not limited to:sanctionsIn,money laundering, fraud and the funding of terrorist organisations."Sanctions" refers to any economic sanctions laws, regulations, embargoes or restrictive measures administered, enacted or enforced from time to time by (i) the Monetary Authority of <Country Name>; (ii) the European Union; (iii) the United Nations; or (iv) any other relevant sanctions authorities.',
      },
    ],
  },
  {
    id: 4,
    title: "CASH WITHDRAWAL",
    clauses: [
      {
        id: "4.1",
        text: 'You may use the Card to make cash withdrawal for each withdrawal via (a) any ATM that accepts VISA branded prepaid card or (b) any Service Provider as notified by <Company Abbreviation> from time to time in jurisdictions where such cash withdrawal would not contravene local laws ("Cash Withdrawal"). "Service Provider" refers to such persons whom <Company Abbreviation> may from time to time utilise to facilitate the provision of services to you, including but not limited to other financial institutions, third party banking comparators and/or such other payment providers that <Company Abbreviation> may from time to time utilise or determine. For the avoidance of doubt, Service Providers do not act as agents of <Company Abbreviation>.',
      },
      {
        id: "4.2",
        text: "The Card is further subject to a Cash Withdrawal aggregate daily limit of USD1,500 (or currency equivalent).",
      },
      {
        id: "4.3",
        text: "Notwithstanding clause 4.1, if <Company Abbreviation> determines in its sole discretion that You are a resident of <Country Name>, You may not use the Card to make any Cash Withdrawal in exchange for <Country Name> Dollars in <Country Name> and may not request <Company Abbreviation> or its Service Provider to carry out any Cash Withdrawal on Your behalf.",
      },
    ],
  },
  {
    id: 5,
    title: "<Company Abbreviation> PAY APP",
    clauses: [
      {
        id: "5.1",
        text: '<Company Abbreviation> hereby grants You a non-exclusive, non-transferable, non-sublicensable, revocable license to use <Company Abbreviation> mobile application ("<Company Abbreviation> PAY APP")applicable laws, rules, VISA rules and these Terms and Conditions for the sole purpose of facilitating Your use of the Card until such time Your Card has been terminated pursuant to these terms and conditions.',
      },
      {
        id: "5.2",
        text: "The use of <Company Abbreviation> PAY APP is subject to these terms and conditions and any other rules and policies imposed by any Appstore provider or operator that makes available the <Company Abbreviation> PAY APP to You.",
      },
      {
        id: "5.3",
        text: "<Company Abbreviation> may at Our sole discretion update the <Company Abbreviation> PAY APP from time to time. During such time, You may not be able to access the <Company Abbreviation> PAY APP and You may be required to update Your device's browser to the latest version after such update.",
      },
      {
        id: "5.4",
        text: "You shall be solely responsible, at your own cost and expense, for obtaining, installing and maintaining such suitable device, software, and communication means as may be required to use the <Company Abbreviation> PAY APP.",
      },
    ],
  },
  {
    id: 6,
    title: "THE E-WALLET",
    clauses: [
      {
        id: "6.1",
        text: 'The Card is also linked to a virtual wallet ("E-Wallet") which You may use to facilitate: (a) the transfer of funds between Your E-Wallet and the E-Wallet of another user in the Card Currency or a Supported Currency; and (b) the conversion of the Card Currency to another currency supported by <Company Abbreviation> (a "Supported Currency") at such exchange rate displayed on the <Company Abbreviation> PAY APP at the time of conversion',
      },
      {
        id: "6.2",
        text: "You acknowledge and agree that the E-Wallet is not a bank account and does not offer the features and benefits of a traditional bank account and the funds held in the E-Wallet in connection do not constitute deposits and do not earn any interest.",
      },
      {
        id: "6.3",
        text: "References to the Card in these Terms and Conditions, shall unless otherwise stated, be deemed to include the E-Wallet.",
      },
    ],
  },
  {
    id: "6W",
    title: "Withdrawal from E-Wallet",
    clauses: [
      {
        id: "6.4",
        text: 'Subject to Clause 6.5, You may withdraw the funds in the E-Wallet ("Wallet Withdrawal") in the Card Currency at any time in accordance with these Terms and Conditions.',
      },
      {
        id: "6.5",
        text: "You may not withdraw funds from the E-Wallet if after deducting all Fees and any other amounts due and owing to <Company Abbreviation>, such Wallet Withdrawal would result in negative balance in the E-Wallet.",
      },
      {
        id: "6.6",
        text: 'Upon receipt of a Wallet Withdrawal request, <Company Abbreviation> shall initiate a transfer of funds (which may be net of any Fees, applicable bank charges and taxes) ("Remittance") to such bank account held in Your name and located in an Approved Jurisdiction ("Cardtholder Bank Account") or a bank account in the name of a third party located in an Approved Jurisdiction ("Third Party Bank Account") as may be approved by <Company Abbreviation> in writing from time to time in such amount and currency as indicated in the Wallet Withdrawal request. "Approved Jurisdiction" refers to those countries as notified by <Company Abbreviation> to You from time to time being where <Company Abbreviation> has the ability to facilitate the Remittance under these Terms and Conditions.',
      },
      {
        id: "6.7",
        text: 'You shall ensure that the details of Cardholder Bank Account or any Third Party Bank Account are correct and complete and shall notify <Company Abbreviation> in writing in advance of any change to Cardholder Bank Account or Third Party Bank Account. If <Company Abbreviation> has not notified of such change and has had a reasonable opportunity to act on it, <Company Abbreviation> shall be entitled to continue making any Remittance to the existing Cardholder Bank Account or Third Party Bank Account. <Company Abbreviation> shall not be liable for any Losses arising from any Remittance due to incorrect or incomplete details provided by You or Your failure to inform <Company Abbreviation> in advance of any change to Cardholder Bank Account or Third Party Bank Account. "Losses" refers to means any liability of any kind, loss, claim, damage, interest, fine, penalty, fee, charge, cost and/or expense (including any foreign exchange losses and reasonable incurred legal and other professional fees, costs and/or expenses).',
      },
      {
        id: "6.8",
        text: 'You acknowledge and agree that the tax authorities of certain countries and/or jurisdictions may declare certain tax, fee and/or tariff ("Remittance Taxes") from the Remittance. If any Remittance is subject to Remittance Tax, the amount You or any third party actually receives may be different from the amount stated in the instruction ("Shortfall"). <Company Abbreviation> shall not be required to make up for such Shortfall under any circumstances. You further acknowledge and agree that Remittance Taxes are subject to changes from time to time and <Company Abbreviation> is under no obligation to notify You of any such changes.',
      },
      {
        id: "6.9",
        text: "Where foreign currency conversion is required pursuant to any Withdrawal request, <Company Abbreviation> shall apply the prevailing exchange at the time of conversion plus a percentage of the margin as set out in Appendix II..",
      },
    ],
  },
];
