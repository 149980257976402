import React from 'react'
import { Link } from 'react-router-dom';

const TermsConditionsFooter = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className="flex flex-col sm:flex-row justify-center sm:justify-between py-4 text-center gap-2">
            <Link to='/terms-conditions' className="text-sm xl:text-base text-black opacity-70">
                Terms & Conditions
            </Link>
            <p className="text-sm xl:text-base text-black opacity-70">
                © Copyright {currentYear}, All Rights Reserved by M3 financial{" "}
            </p>
        </div>
    )
}

export default TermsConditionsFooter